import React, {useEffect, useState} from "react";
import { format } from 'date-fns';
import DayTimePicker from "./DayTimePicker";
import {addDays} from 'date-fns';
import {DayPickerData, SurveillanceDayCostCalculator, SurveillanceTimes} from "./util/SurveillanceUtil";
import {Navigate} from "react-router-dom";
import Banner from "./Banner";
import SurveillanceOperationCheckoutResponse from "./model/SurveillanceOperationCheckoutResponse";
import {trackButtonAnalytics} from "./util/TrackButtonAnalytics";
import axiosClient from "./config/AxiosConfig";
import {useLocation} from "react-router-dom";
import {typeBanner} from "./util/ServiceTypeUtil";


function BookSurveillance() {

    const [location, setLocation] = useState("")

    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const servicesType = queryParams.get('type')

    const [numberOfAgents, setNumberOfAgents] = useState(2);
    const [selectedDates, setSelectedDates] = useState(new Map<String, DayPickerData>());

    const today = new Date();
    const [isLoading, setIsLoading] = useState(true);
    const [numberOfAvailableDates, setNumberOfAvailableDates] = useState(8);
    const authTokenKey = "authToken";
    const [existingToken, setExistingToken] = useState("");

    const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);
    const [shouldRedirectToCheckout, setShouldRedirectToCheckout] = useState(false);
    const [paymentSessionCheckoutRedirectUrl, setPaymentSessionCheckoutRedirectUrl] = useState("");
    const [operationId, setOperationId] = useState("");
    const [formFailed, setFormFailed] = useState(false);

    const [restCallInProgress, setRestCallInProgress] = useState(false);

    interface SurveillanceInfo {
        location: string;
        numberOfAgents: number;
        selectedDates: Array<DayPickerData>;
    }

    interface CreateOperationRequest {
        location: string;
        numberOfAgents: number;
        dates: Array<DayPickerDataRequest>;
    }

    interface DayPickerDataRequest {
        date: string;
        timeFrom: number;
        timeTo: number;
    }

    // Function to transform DayPickerData to DayPickerDataRequest
    const transformDayPickerDataToRequest = (dayPickerData: DayPickerData): DayPickerDataRequest => {
        return {
            date: format(dayPickerData.date, 'yyyy-MM-dd'),
            timeFrom: dayPickerData.startTime.valueOf(),
            timeTo: dayPickerData.endTime.valueOf()
        };
    };

    function totalCostCalculator(): number {
        let totalCost = 0;
        [...selectedDates]
            .filter(([key, value]) => value.selected === true)
            .sort().map(([key, value]) => (
            totalCost = totalCost + SurveillanceDayCostCalculator(value, numberOfAgents)
        ))
        return totalCost;
    }

    const dayPickerCallback = (dayPickerData: DayPickerData) => {
        console.log(`DayPicker callback: ${isoDate(dayPickerData.date)} ,selected: ${dayPickerData.selected}, startTime: ${dayPickerData.startTime}, endTime: ${dayPickerData.endTime}`);
        updateSelectedDates(dayPickerData.date, dayPickerData);
    }

    useEffect(() => {
        console.log("Book-surveillance check state started");

        let previouslySelectedDatesMap = new Map(selectedDates);
        const surveillanceInfo = localStorage.getItem('surveillanceInfo');
        if (surveillanceInfo) {
            const parsedSurveillanceInfo: SurveillanceInfo = JSON.parse(surveillanceInfo);
            previouslySelectedDatesMap = parsedSurveillanceInfo.selectedDates.reduce((map, item) => {
                const dateData: DayPickerData = {
                    date: new Date(item.date),
                    endTime: item.endTime,
                    selected: item.selected,
                    startTime: item.startTime
                }
                console.log(`Setting in map previous date: ${item.date}. Selected: ${item.selected}`);
                map.set(isoDate(new Date(item.date)), dateData);
                return map;
            }, new Map<string, DayPickerData>());
            setSelectedDates(previouslySelectedDatesMap);
        }
        setIsLoading(false);
        // localStorage.removeItem('surveillanceInfo');
        // localStorage.removeItem('loginRedirect');
        console.log("Book-surveillance check state finished");
    },[]);


    useEffect(() => {
        console.log("Book-surveillance basic info - started");
        setExistingToken(localStorage.getItem(authTokenKey) || "");

        const surveillanceInfo = localStorage.getItem('surveillanceInfo');
        if (surveillanceInfo) {
            const parsedSurveillanceInfo: SurveillanceInfo = JSON.parse(surveillanceInfo);
            setNumberOfAgents(parsedSurveillanceInfo.numberOfAgents);
            setLocation(parsedSurveillanceInfo.location);
        }
        localStorage.removeItem('surveillanceInfo');
        localStorage.removeItem('loginRedirect');
        console.log("Book-surveillance  basic info - finished");
    },[]);

    function selectedNumberOfAgents(underCheck: number): string {
        if (numberOfAgents === underCheck) {
            return "button-primary";
        } else {
            return "button-gray-light-outline";
        }
    }

    function dayPickersList() {
        let pickers = [];
        for (let i = 0; i < numberOfAvailableDates; i++) {

            const now = new Date();
            if ( !(i === 0 && (now.getHours() > 11 || now.getHours() < 7))) {
                const date = addDays(today, i);
                const previouslySelected = selectedDates.get(isoDate(date));
                // console.log(`Setting day picker: ${date.toDateString()}. Previously selected: ${previouslySelected?.selected ?? 'NO'}`)
                pickers.push(<DayTimePicker
                    key={isoDate(date)}
                    date={date}
                    callback={dayPickerCallback}
                    selected={previouslySelected?.selected}
                    startTime={previouslySelected?.startTime}
                    endTime={previouslySelected?.endTime}></DayTimePicker>);
            }

        }
        return pickers;
    }

    const handleChangeLocation = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setLocation(value);
    }


    const [moreDaysAvailable, setMoreDaysAvailable] = useState(true);
    function addMoreDays() {
        console.log("Adding more days. Current: " + numberOfAvailableDates + ", more available: " + moreDaysAvailable);
        if (numberOfAvailableDates < 32) {
            setNumberOfAvailableDates(numberOfAvailableDates + 4);
        }

        if (numberOfAvailableDates + 4 > 32) {
            setMoreDaysAvailable(false);
        }
    }

    const updateSelectedDates = (k: Date, v: DayPickerData) => {
        // console.log("---------- Update selected dates START------------");
        // selectedDates.forEach((value, key ) => {
        //     console.log(`Old Date: ${key}. Selected: ${value.selected}`);
        // })
        const dateIso = isoDate(k);
        const newMap = new Map(selectedDates);
        if (v.selected) {
            console.log(`Adding date: ${dateIso}`)
            newMap.set(dateIso,v);
        } else {
            console.log(`Deleting date: ${dateIso}`)
            newMap.delete(dateIso);
        }
        setSelectedDates(newMap);
        // newMap.forEach((value, key ) => {
        //     console.log(`New Date: ${key}. Selected: ${value.selected}`);
        // })
        // console.log("---------- Update selected dates END------------");
    }

    function isoDate(date: Date): string {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    function plural(input: number): string {
        if (input == 1) {
            return ""
        }
        return "s"
    }

    function operationReady(): boolean {
        return location !== "" && location !== undefined && location !== null && selectedDates.size > 0;
    }

    function goToLoginRegister() {
        const surveillanceInfo: SurveillanceInfo = {
            numberOfAgents: numberOfAgents,
            selectedDates: Array.from(selectedDates.values()),
            location: location
        };

        localStorage.setItem('surveillanceInfo', JSON.stringify(surveillanceInfo));
        localStorage.setItem('loginRedirect', "SURVEILLANCE");
        setShouldRedirectToLogin(true);
    }

    function checkoutButtonClass(): string {
        if (canCheckout() && !restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    function isLoggedIn(): boolean {
        return (existingToken !== undefined && existingToken !== "" && existingToken !== null)
    }

    function canCheckout(): boolean {
        return (isLoggedIn() && operationReady());
    }

    async function checkout() {
        const formData: CreateOperationRequest = {
            location: location,
            numberOfAgents: numberOfAgents,
            dates: Array.from(selectedDates.values()).map(transformDayPickerDataToRequest)
        }
        setFormFailed(false);
        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post<SurveillanceOperationCheckoutResponse>('/operations-service/operations/surveillance', formData);
            console.log("SUCCESS:" + response)
            console.log("Operation id: " + response.data.surveillanceOperation.id + ", operation code: " + response.data.surveillanceOperation.operationCode);
            console.log("Stripe redirect url: " + response.data.checkoutSession.checkoutRedirectUrl);
            setFormFailed(false);
            setOperationId(response.data.surveillanceOperation.id)
            setPaymentSessionCheckoutRedirectUrl(response.data.checkoutSession.checkoutRedirectUrl);
            setShouldRedirectToCheckout(true);
            setRestCallInProgress(false);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    if (isLoading) {
        return <div>Loading, please wait...</div>;
    }

    return <>
        {shouldRedirectToLogin && <Navigate replace to="/login" />}
        {/*{shouldRedirectToCheckout && <Navigate replace to={"/checkout?operationId=" + operationId} />}*/}
        {shouldRedirectToCheckout && window.location.replace(paymentSessionCheckoutRedirectUrl)}
        <Banner title={"Covert Surveillance"} subtitle={undefined} description={"Book Now"} banner={typeBanner(servicesType)} buttonTitle={undefined} buttonLink={undefined} />
        <div className={"container"}>

            <div className={"section-lg text-center text-md-left"}>
                <div className={"container"}>
                    <p>Covert surveillance is a discrete method to gather information. This technique allows us to collect accurate details and share them with you in real-time, while maintaining your confidentiality.</p>
                    <p>Our team has extensive experience conducting covert surveillance across the UK, providing valuable information for our clients. Whether you need us for a single meeting or a longer investigation, our skilled team can quickly be on-site anywhere in the UK. We are committed to delivering answers with complete professionalism and discretion.</p>
                    <p>After booking with us, you will be sent a briefing form where we will gather a detailed picture of your current situation and other information to match you with the best investigator.</p>

                    <h4>Provide the address where you would like the surveillance to start</h4>
                    <p>This can be amended in the future</p>

                    <div className="form-wrap form-wrap_icon linear-icon-feather">
                        <textarea className="form-input textarea-location" id="contact-message" name="message" rows={2} onChange={handleChangeLocation} value={location}></textarea>
                    </div>
                    <br/><br/>

                    <h4>How many agents do you need?</h4>
                    <div className="alert alert-info alert-info-custom" role="alert">
                        In order to have the best chance of keeping track of the subject we recommend 2 agents per subject. So if you need surveillance of one person, we recommend at least 2 agents. For 2 persons we recommend 4 agents etc.
                        If you need more than 8 agents please give us a call.
                    </div>
                    {/*<input type="range" className="numberOfAgentsRangePicker form-range" min="1" max="8" step="1" id="customRange3" value={numberOfAgents} onChange={handleChangeNumberOfAgents}/>*/}
                    <div>
                        <div className={ "select-date button " + selectedNumberOfAgents(1)} onClick={() => {setNumberOfAgents(1); trackButtonAnalytics({action: "setNumberOfAgents-1"})}}>1</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(2)} onClick={() => {setNumberOfAgents(2); trackButtonAnalytics({action: "setNumberOfAgents-2"})}}>2</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(3)} onClick={() => {setNumberOfAgents(3); trackButtonAnalytics({action: "setNumberOfAgents-3"})}}>3</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(4)} onClick={() => {setNumberOfAgents(4); trackButtonAnalytics({action: "setNumberOfAgents-4"})}}>4</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(5)} onClick={() => {setNumberOfAgents(5); trackButtonAnalytics({action: "setNumberOfAgents-5"})}}>5</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(6)} onClick={() => {setNumberOfAgents(6); trackButtonAnalytics({action: "setNumberOfAgents-6"})}}>6</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(7)} onClick={() => {setNumberOfAgents(7); trackButtonAnalytics({action: "setNumberOfAgents-7"})}}>7</div>
                        <div className={ "select-date button " + selectedNumberOfAgents(8)} onClick={() => {setNumberOfAgents(8); trackButtonAnalytics({action: "setNumberOfAgents-8"})}}>8</div>
                    </div>
                    <h6 className={"numberOfAgents"}>{numberOfAgents} Agent{plural(numberOfAgents)}</h6>
                    <br/>
                    <br/>

                    <h4>When does the operation take place?</h4>
                    <p>Minimum 5 hours per day</p>
                    <div className={"row row-50 text-center"}>
                        {dayPickersList()}
                    </div>
                    {moreDaysAvailable && <button className="button button-secondary" onClick={() => {addMoreDays();  trackButtonAnalytics({action: "addMoreDays"})}}>Later dates</button>}


                    <br/><br/><br/>
                    <h4>Operation summary</h4>
                    <h6>Number of agents: {numberOfAgents}</h6><br/>
                    <h6>Selected Dates: {selectedDates.size}</h6>

                    {[...selectedDates]
                        .filter(([key, value]) => value.selected === true).length > 0 &&
                        <table className={"cost-summary-table"}>
                            <thead>
                                <tr><th>Date</th><th>From</th><th>To</th><th>Cost</th></tr>
                            </thead>
                            <tbody>
                            {[...selectedDates]
                                .filter(([key, value]) => value.selected === true)
                                .sort().map(([key, value]) => (
                                <tr key={key.valueOf()}>
                                    <td>{value.date.toDateString()}</td>
                                    <td>{SurveillanceTimes.get(value.startTime.valueOf())}</td>
                                    <td>{SurveillanceTimes.get(value.endTime.valueOf())}</td>
                                    <td>{`£${SurveillanceDayCostCalculator(value, numberOfAgents)}`}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }

                    <h4>Total cost: £{totalCostCalculator()}</h4>


                    <div className="alert alert-info alert-info-custom" role="alert">
                        Once your operation is confirmed we will request more detailed information.
                    </div>

                    <>
                        {!operationReady() &&
                            <div className="alert alert-info alert-warning" role="alert">
                                Please fill all the required information to checkout.
                            </div>
                        }
                    </>

                    {(existingToken === undefined || existingToken === "" || existingToken === null) && operationReady() &&
                        <>
                            <div className="alert alert-info alert-warning" role="alert">
                                You need to be logged in to checkout.
                            </div>
                            <button className="button button-primary surveillanceFormButton" onClick={() => {goToLoginRegister();  trackButtonAnalytics({action: "goToLoginRegister"})}}>Continue to Login/Register</button>
                            <br />
                            <br />
                        </>
                    }

                    {formFailed &&
                        <div className="alert alert-danger" role="alert">
                            Request failed. Please try again later.
                        </div>
                    }

                    {isLoggedIn() &&
                        <>

                            <>
                                <button disabled={!canCheckout() || restCallInProgress} className={"button button-primary" + checkoutButtonClass() + " surveillanceFormButton" } onClick={() => {checkout();  trackButtonAnalytics({action: "checkout"})}}>Checkout</button>
                            </>
                        </>
                    }

                </div>
            </div>
        </div>
    </>
}

export default BookSurveillance;