function WhatIsSurveillance() {


    return <>
        <section className="section-md bg-gray-lighter text-left">
            <div className="container">
                <div className="row justify-content-md-center row-30 row-md-50">
                    <div className="col-md-11 col-lg-10 col-xl-6">
                        <h4 className="heading-decorated">What is Surveillance?</h4>
                        <p className="heading-6 text-width-1">Surveillance is the monitoring of people, activities and assets to reduce risk and promote safety.</p>
                        <p>It can help expose truth and prevent issues like fraud or theft while ensuring a secure environment for everyone involved. Our goal is to provide peace of mind by helping to protect what matters most to you.</p>

                        <h5>Prices from just £55 per hour</h5>
                    </div>
                    <div className="col-md-11 col-lg-10 col-xl-6"><img src="../../images/surveillance-default.jpeg" alt="" width="652" height="491"/>
                    </div>
                </div>
            </div>
        </section>
    </>


}
export default WhatIsSurveillance;