import React, {useState} from 'react';
import axiosClient from "./config/AxiosConfig";

interface ContactUsFormState {
    name: string;
    email: string;
    phone: string;
    message: string
}

function Contact() {

    const [restCallInProgress, setRestCallInProgress] = useState(false);

    function formButtonClass(): string {
        if (!restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);

    const [formData, setFormData] = useState<ContactUsFormState>({
        name: '',
        email: '',
        phone: '',
        message: ''
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}))
    }

    const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}))
    }

    function resetForm() {
        setFormData(prevData => ({...prevData, "name": ''}))
        setFormData(prevData => ({...prevData, "email": ''}))
        setFormData(prevData => ({...prevData, "phone": ''}))
        setFormData(prevData => ({...prevData, "message": ''}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formData.message || !formData.phone || !formData.email || !formData.name) {
            setValidationErrors(true);
            return;
        } else {
            setValidationErrors(false);
        }
        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post('/user-service/contact/contactus', formData);
            console.log("SUCCESS:" + response);
            setFormSentSuccessfully(true);
            setFormFailed(false);
            resetForm()
            setRestCallInProgress(false);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    return (
        <>
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        <div className="col-md-5 col-lg-4">
                            <h4 className="heading-decorated">Contact Details</h4>
                            <ul className="list-sm contact-info">
                                <li>
                                    <dl className="list-terms-minimal">
                                        <dt>Address</dt>
                                        <p>First Floor, </p>
                                        <p>85 Great Portland Street, </p>
                                        <p>London, United Kingdom, </p>
                                        <p>W1W 7LT</p>
                                    </dl>
                                </li>
                                <li>
                                    <dl className="list-terms-minimal">
                                        <dt>E-mail</dt>
                                        <dd><a href="mailto:info@irisinvestigators.com">info@irisinvestigators.com</a></dd>
                                    </dl>
                                </li>
                                <li>
                                    <div>
                                        <p>Iris Investigators</p>
                                        <p>SALUS GLOBAL LTD</p>
                                        <p>Company number 15823720</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h4 className="heading-decorated">Get in Touch</h4>

                            {formSentSuccessfully &&
                                <div className="alert alert-success" role="alert">
                                    Thank you very much for contacting us. Your message has been received. A member of our team will contact you shortly.
                                </div>
                            }
                            {formFailed &&
                                <div className="alert alert-danger" role="alert">
                                    Request failed. Please try again later.
                                </div>
                            }
                            {validationErrors &&
                                <div className="alert alert-warning" role="alert">
                                    Please fill all the fields and then submit the form.
                                </div>
                            }

                            <form className="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
                                  data-form-type="contact" onSubmit={handleSubmit}>

                                <p className={"formFieldLabel"}>Your Name</p>
                                <div className="form-wrap form-wrap_icon linear-icon-man">
                                    <input className="form-input" id="contact-name" type="text" name="name"
                                           data-constraints="@Required" onChange={handleChange} value={formData.name}/>
                                </div>

                                <p className={"formFieldLabel"}>Your email</p>
                                <div className="form-wrap form-wrap_icon linear-icon-envelope">
                                    <input className="form-input" id="contact-email" type="email" name="email"
                                           data-constraints="@Email @Required" onChange={handleChange} value={formData.email}/>
                                </div>

                                <p className={"formFieldLabel"}>Your phone number</p>
                                <div className="form-wrap form-wrap_icon linear-icon-phone">
                                    <input className="form-input" id="contact-phone" type="phone" name="phone"
                                           data-constraints="@Required" onChange={handleChange} value={formData.phone}/>
                                </div>

                                <p className={"formFieldLabel"}>Your message</p>
                                <div className="form-wrap form-wrap_icon linear-icon-feather">
                                    <textarea className="form-input" id="contact-message" name="message"
                                              data-constraints="@Required" onChange={handleChangeTextArea} value={formData.message}></textarea>
                                </div>
                                {!formSentSuccessfully &&
                                    <button disabled={restCallInProgress} className={"button " + formButtonClass()} type="submit">send</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Contact;