import React, {useEffect, useState} from "react";
import Banner from "./Banner";
import {Navigate, useLocation} from "react-router-dom";
import SurveillanceOperationModel from "./model/SurveillanceOperationModel";
import AdminOperationsSection from "./admin/AdminOperationsSection";
import OperationsResponse from "./admin/OperationsResponse";
import axiosClient from "./config/AxiosConfig";


function AdminOperations() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [operations, setOperations] = useState<OperationsResponse>()
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axiosClient().get<OperationsResponse>('/operations-service/admin/operations');
                console.log("SUCCESS:" + response);
                setOperations(response.data);
            } catch (error) {
                console.error("FAILED:" + error);
                setShouldRedirect(true);
            }
        }

        fetchData();

    }, []);

    function operationType(operation: SurveillanceOperationModel): string {
        if (operation?.type === "SURVEILLANCE") {
            return "Covert Surveillance";
        }
        return "";
    }


    return <>

        {shouldRedirect && <Navigate replace to={"/"} />}

        <Banner title={"Admin - Operations"} subtitle={undefined} description={undefined}  banner={undefined} buttonTitle={undefined} buttonLink={undefined}/>

        <div className={"container"}>

            <div className={"section-lg text-center text-md-left"}>
                <div id={"checkoutForm"} className={"container text-center"}>
                    <br/>

                    <AdminOperationsSection title={"All Operations"} operations={operations} />

                </div>
            </div>
        </div>
    </>
}

export default AdminOperations;