import React from "react";
import WhyChooseOurService from "./WhyChooseOurService";
import Banner from "./Banner";
import Blurb from "./Blurb";
import Services from "./Services";
import HowItWorks from "./HowItWorks";
import WhatIsSurveillance from "./WhatIsSurveillance";

const Home = () => {
    return (
        <>
            <div className="App">

                <Banner title={"INSTANT  SURVEILLANCE QUOTE"} description={"Book online within minutes from just £55 per hour"} buttonTitle={"Book Now"} buttonLink={"/book-surveillance"} subtitle={undefined} banner={undefined} />

                {/*<section id="main-page-services" className="section-md bg-default">*/}
                {/*    <h2 className="main-page-subheader">WHAT PROBLEM CAN WE HELP YOU SOLVE?</h2>*/}
                {/*    <div className="container">*/}
                {/*        <div className="row row-50 justify-content-md-start justify-content-lg-start">*/}

                {/*            <Blurb title={"I believe someone is being unfaithful"} buttonTitle={"Instant Quote"} icon={"linear-icon-heart"} linkTo={"/services/cheating"} />*/}
                {/*            <Blurb title={"I need evidence for court proceedings"} buttonTitle={"Instant Quote"} icon={"linear-icon-apartment"} linkTo={"/services/court"} />*/}
                {/*            <Blurb title={"I want to make sure someone is safe"} buttonTitle={"Instant Quote"} icon={"linear-icon-user"} linkTo={"/services/safe"} />*/}
                {/*            <Blurb title={"I need help finding someone"} buttonTitle={"Instant Quote"} icon={"linear-icon-map-marker"} linkTo={"/services/find"} />*/}
                {/*            <Blurb title={"I have a corporate requirement"} buttonTitle={"Instant Quote"} icon={"linear-icon-eye"} linkTo={"/services/corporate"} />*/}
                {/*            <Blurb title={"Other - I would like to talk to someone"} buttonTitle={"Contact Us"} icon={"linear-icon-phone-outgoing"} linkTo={"/contact"} />*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <WhatIsSurveillance />
                <HowItWorks />

                <WhyChooseOurService />

            </div>
        </>
    );
}
export default Home;