import React from "react";
import {NavLink} from "react-router-dom";

function HowItWorks() {
    return <>

        <section className="section novi-bg novi-bg-img section-xl bg-default">
            <div className="container">
                <section className="">
                    <div className="container">
                        <div className="row justify-content-md-center justify-content-lg-between row-50 align-items-center">
                            <div className="col-md-8 col-lg-6">
                                <h4 className="heading-decorated">How it works</h4>
                                <article className="blurb blurb-minimal">
                                    <div className="unit flex-row unit-spacing-md">
                                        <div className="unit-body">
                                            <p className="blurb__title heading-6">1. Register</p>
                                            <p>Register for an account or sign in to your existing account.</p>
                                        </div>
                                    </div>
                                </article>
                                <article className="blurb blurb-minimal">
                                    <div className="unit flex-row unit-spacing-md">
                                        <div className="unit-body">
                                            <p className="blurb__title heading-6">2. Booking System</p>
                                            <p>Use the <NavLink to='/book-surveillance' className={""}>booking system</NavLink> to select the time and dates for
                                                your surveillance </p>
                                        </div>
                                    </div>
                                </article>
                                <article className="blurb blurb-minimal">
                                    <div className="unit flex-row unit-spacing-md">
                                        <div className="unit-body">
                                            <p className="blurb__title heading-6">3. Briefing Form</p>
                                            <p>After payment we will send you a briefing form for you to complete within 24 hours.</p>
                                        </div>
                                    </div>
                                </article>
                                <article className="blurb blurb-minimal">
                                    <div className="unit flex-row unit-spacing-md">
                                        <div className="unit-body">
                                            <p className="blurb__title heading-6">4. Surveillance Operation</p>
                                            <p>The surveillance will be carried out on the dates you requested. During the Operation you will have the option to
                                                have live updates.</p>
                                        </div>
                                    </div>
                                </article>
                                <article className="blurb blurb-minimal">
                                    <div className="unit flex-row unit-spacing-md">
                                        <div className="unit-body">
                                            <p className="blurb__title heading-6">5. Surveillance Completion</p>
                                            <p>Once the surveillance is complete you will receive a detailed log of events during the operation.</p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div className="col-md-7 col-lg-4">
                                <figure className="image-sizing-1"><img src="../images/surveillance-portrait-2.jpeg" alt="" width="391" height="642"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>


                {/*<div className="row row-30 how-it-works-container">*/}
                {/*    <div className={"example-report-container"}>*/}


                {/*        <div className="example-report text-center"><img src="../images/example-report.png" alt=""/></div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </section>

    </>
}

export default HowItWorks;