import React from "react";
import bannerImage from '../images/banner_1.jpg';
import {NavLink, To} from "react-router-dom";

type BannerProps = {
    title: string;
    subtitle: string | undefined;
    description: string | undefined;
    banner: string | undefined;
    buttonTitle: string | undefined;
    buttonLink: To | undefined;

}



function Banner(props: BannerProps) {

    const bannerStyle = {
        backgroundImage: `url(${bannerImageUrl()})`,
        backgroundSize: 'cover',
    };

    function bannerImageUrl(): string {
        if (props.banner === undefined) {
            return bannerImage
        }
        return props.banner
    };

    return <>

        <section className="text-center">
            <section className="section parallax-container">
                <div className="parallax-content parallax-header parallax-light " style={bannerStyle}>
                    <div className="parallax-header__inner">
                        <div className="parallax-header__content">
                            <div className="container">
                                <div className="row justify-content-sm-center justify-content-center">
                                    <div className="col-md-12 col-xl-12">
                                        <h2 className="heading-decorated main-page-header" >{props.title}</h2>
                                        {props.subtitle !== undefined && <h3 className="heading-decorated main-page-header" >{props.subtitle}</h3>}
                                        {props.description !== undefined && <h4>{props.description}</h4>}
                                    </div>
                                    {props.buttonTitle !== undefined &&
                                        <div className={"pt-4 text-center"}>
                                            <NavLink className={"button button-primary"} to={props.buttonLink!!}>{props.buttonTitle}</NavLink>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>

    </>
}

export default Banner;