import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";

function Header() {
    const location = useLocation()

    const authTokenKey = "authToken";
    const [existingToken, setExistingToken] = useState("");

    useEffect(() => {
        console.log('Location changed: ' + location);
        setExistingToken(localStorage.getItem(authTokenKey) || "");
    }, [location]);

    function logout() {
        localStorage.removeItem(authTokenKey);
    }

    return (
        <>

            <header className="page-header">
                <div className="rd-navbar-wrap">
                    <nav className="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
                         data-sm-device-layout="rd-navbar-fixed" data-md-layout="rd-navbar-static"
                         data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-static"
                         data-lg-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static"
                         data-xl-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static"
                         data-xxl-layout="rd-navbar-static" data-stick-up-clone="false" data-sm-stick-up="true"
                         data-md-stick-up="true" data-lg-stick-up="true" data-md-stick-up-offset="115px"
                         data-lg-stick-up-offset="35px">
                        <div className="rd-navbar-inner rd-navbar-search-wrap">
                            <div className="rd-navbar-panel rd-navbar-search-lg_collapsable">
                                <div className="rd-navbar-brand">
                                    <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                                    <NavLink to='/'><img className="brand-name" src="/images/logo_white.png" alt="" width="329" height="40"/></NavLink>
                                </div>
                            </div>
                            <div className="rd-navbar-nav-wrap rd-navbar-search_not-collapsable">
                                <div className="rd-navbar__element rd-navbar-search_collapsable"></div>

                                <div className="rd-navbar-search_collapsable">
                                    <ul className="rd-navbar-nav">
                                        <li><NavLink to='/'>Home</NavLink></li>
                                        <li><NavLink to='/about'>About</NavLink></li>
                                        <li><NavLink to='/faqs'>FAQs</NavLink></li>
                                        <li><NavLink to='/contact'>Contact</NavLink></li>
                                    </ul>
                                </div>
                            </div>

                            {(existingToken === undefined || existingToken === "" || existingToken === null) &&
                                <ul className="rd-navbar-nav align-middle">
                                    <li><NavLink to='/login'>Login</NavLink></li>
                                    <li><NavLink to='/register'>Register</NavLink></li>
                                </ul>
                            }

                            {(existingToken !== undefined && existingToken !== "" && existingToken !== null) &&

                                <ul className="rd-navbar-nav">
                                    <li><Link to='/' onClick={logout}>Logout</Link></li>
                                </ul>
                            }

                            <ul className="rd-navbar-nav align-middle">
                                <li><NavLink to='/book-surveillance' className={"button"}>Book Now</NavLink></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

        </>
    );
}

export default Header;