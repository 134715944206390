import React from 'react';
import {NavLink} from "react-router-dom";

function Footer() {
    return (
        <>
            <section className="pre-footer-corporate bg-gray-dark">
                <div className="container">
                    <div className="row justify-content-sm-center justify-content-lg-start row-30 row-md-60">
                        <div className="col-sm-10 col-md-6 col-lg-10 col-xl-3">
                            <a className="brand" href="index.html"><NavLink to='/'><img className="brand-name" src="/images/logo_white.png" alt="" width="329"
                                                                                        height="40"/></NavLink></a>
                            <div className={"pt-3"}>
                                <p>IRIS Investigators offers professional surveillance services for corporate and private clients through a user-friendly,
                                    transparent online booking platform.</p>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 col-lg-3 col-xl-3">
                            <h6>Navigation</h6>
                            <ul className="list-xxs list-primary">

                                <li><NavLink to='/book-surveillance' className={""}>Book Now</NavLink></li>
                                <li><NavLink to='/about'>About</NavLink></li>
                                <li><NavLink to='/faqs'>FAQs</NavLink></li>
                                <li><NavLink to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-sm-10 col-md-6 col-lg-5 col-xl-3">
                            {/*<h6>Testimonials</h6>*/}
                            {/*<ul className="list-xs">*/}
                            {/*    <li>*/}
                            {/*        <article className="comment-minimal">*/}
                            {/*            <p className="comment-minimal__author">Brian Williamson on</p>*/}
                            {/*            <p className="comment-minimal__link"><a href="standard-post.html">Construction's core values have been shaped over more*/}
                            {/*                than*/}
                            {/*                10 years of</a></p>*/}
                            {/*        </article>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <article className="comment-minimal">*/}
                            {/*            <p className="comment-minimal__author">Brian Williamson on</p>*/}
                            {/*            <p className="comment-minimal__link"><a href="standard-post.html">Construction's core values have been shaped over more*/}
                            {/*                than*/}
                            {/*                10 years of</a></p>*/}
                            {/*        </article>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <article className="comment-minimal">*/}
                            {/*            <p className="comment-minimal__author">Brian Williamson on</p>*/}
                            {/*            <p className="comment-minimal__link"><a href="standard-post.html">How to save 10 grand a year with digital*/}
                            {/*                blueprints</a>*/}
                            {/*            </p>*/}
                            {/*        </article>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                        <div className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
                            <h6>Contact</h6>
                            <ul className="list-xs">
                                <li>
                                    <dl className="list-terms-minimal">
                                        <dt>Address</dt>
                                        <p>First Floor, </p>
                                        <p>85 Great Portland Street, </p>
                                        <p>London, United Kingdom, </p>
                                        <p>W1W 7LT</p>
                                    </dl>
                                </li>
                                <li>
                                    <dl className="list-terms-minimal">
                                        <dt>E-mail</dt>
                                        <dd><a href="mailto:info@irisinvestigators.com">info@irisinvestigators.com</a></dd>
                                    </dl>
                                </li>
                                <li>
                                    <div>
                                        <p>Iris Investigators</p>
                                        <p>SALUS GLOBAL LTD</p>
                                        <p>Company number 15823720</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer-corporate bg-white">
                <div className="container">
                    <div className="footer-corporate__inner">
                        <p className="rights"><span>Iris Investigators</span><span>&nbsp;&copy;&nbsp;</span><span className="copyright-year"></span>. All rights reserved.</p>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer;