import React, {useEffect, useState} from "react";
import Banner from "./Banner";
import {Navigate, useLocation} from "react-router-dom";
import axiosClient from "./config/AxiosConfig";
import UsersResponse from "./admin/UsersResponse";
import AdminUsersSection from "./admin/AdminUsersSection";


function AdminUsers() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [users, setUsers] = useState<UsersResponse>()
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axiosClient().get<UsersResponse>('/operations-service/admin/users');
                console.log("SUCCESS:" + response);
                setUsers(response.data);
            } catch (error) {
                console.error("FAILED:" + error);
                setShouldRedirect(true);
            }
        }

        fetchData();

    }, []);

    return <>

        {shouldRedirect && <Navigate replace to={"/"} />}

        <Banner title={"Admin - Users"} subtitle={undefined} description={undefined} banner={undefined} buttonTitle={undefined} buttonLink={undefined}/>

        <div className={"container"}>

            <div className={"section-lg text-center text-md-left"}>
                <div id={"checkoutForm"} className={"container text-center"}>
                    <br/>

                    <AdminUsersSection title={"All Users"} users={users} />

                </div>
            </div>
        </div>
    </>
}

export default AdminUsers;